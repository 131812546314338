import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import ImageButton from './ImageButton';
import icon from '../images/textSearchButton.png';

const TextSearchButton = ({ buttonStyle }) => {
    const { tenant, substore } = useParams();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    let navigate = useNavigate();

    const handleClickOpen = () => {
        setShowSearchBar(!showSearchBar);
    };

    const handleInputChange = (event) => {
        setSearchInput(event.target.value.toUpperCase());
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        redirectToStock(searchInput);
    };

    const redirectToStock = (result) => {
        if (result !== ''){
            if (tenant == null) {
                return navigate('/' + result);
            }
            const urlToNav = substore ? `/${tenant}/${substore}/${result}` : `/${tenant}/${result}`;
            navigate(urlToNav);
        }
    };

    return (
        <div>
            <ImageButton icon={icon} handleClickOpen={handleClickOpen} style={{ ...buttonStyle }} />
            {showSearchBar && (
                <form onSubmit={handleFormSubmit} style={{ marginTop: '10px', width: '100%' }}>
                    <TextField
                        autoFocus
                        placeholder="Buscar..."
                        value={searchInput}
                        onChange={handleInputChange}
                        style={{ width: '100%' }}
                        inputProps={{
                            inputMode: 'text',
                            className: 'white-caret-input',
                            style: { color: 'white' }
                        }}
                        InputLabelProps={{
                            style: { color: 'white' }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white', // White border
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white', // White border on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'white', // White border when focused
                                },
                            },
                        }}
                    />
                </form>
            )}
        </div>
    );
}

export default TextSearchButton;
