import React from 'react';
import PropTypes from 'prop-types';
import './BarcodeResult.css';

const BarcodeResult = ({ result, redirectToStock }) => (
    <>
        <button className="scannedBarcode" onClick={() => redirectToStock(result)}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                <div style={{
                    width: '10px', // Ajusta el tamaño del círculo según sea necesario
                    height: '10px', // Ajusta el tamaño del círculo según sea necesario
                    borderRadius: '50%',
                    backgroundColor: 'lightgreen',
                    marginRight: '5px', // Espacio entre el círculo y el texto
                }}></div>
                <p className="labelVerStock">Ver Stock</p>
            </div>
              <p>{result}</p>
              <p className="lupa" ></p>
        </button>
    </>
);

BarcodeResult.propTypes = {
    result: PropTypes.object
};

export default BarcodeResult;
