import React from 'react';
import Button from '@mui/material/Button';

const ImageButton = ({ icon, handleClickOpen, style }) => {
    return (
    <div>
        <Button onClick={handleClickOpen} style={{ width: '80px', padding: '0', border: 'none', background: 'none', ...style}}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={icon} alt="Scan" />
        </Button>
    </div>
    );
}

export default ImageButton;
