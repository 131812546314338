import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Quagga from '@ericblade/quagga2';
import Button from '@mui/material/Button';
import OverlayScanner from '../components/OverlayScanner';
import BarcodeScanner from '../components/BarcodeScanner';
import BarcodeResult from '../components/BarcodeResult';
import SwitchCameraButton from '../components/SwitchCameraButton';
import ZoomButton from '../components/ZoomButton';
import './BarcodeScannerView.css';
import { isNullOrUndef } from 'chart.js/helpers';
import TextSearchButton from '../components/TextSearchButton';

const ZOOM_LEVEL = 2.5;

const filterCameras = (cameras) => {
    const filteredCameras = cameras.filter(camera => {
        const cameraCapabilities = camera.getCapabilities();
        console.log('Camera', camera.label, 'Info', cameraCapabilities);
        return cameraCapabilities.facingMode && cameraCapabilities.facingMode[0] !== "user";

    })
    return filteredCameras;
}

const BarcodeScannerView = () => {
    const { tenant, substore } = useParams();
    // const [scanning, setScanning] = useState(true); // toggleable state for "should render scanner"
    const [cameras, setCameras] = useState([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
    const [cameraId, setCameraId] = useState(null); // id of the active camera device
    const [selectedCameraLabel, setSelectedCameraLabel] = useState(null); // id of the active camera device
    const [isMounting, setIsMounting] = useState(true); //camera selection mounting

    const [cameraError, setCameraError] = useState(null); // error message from failing to access the camera
    const [result, setResult] = useState(null); // result of the last scan, as returned by Quagga.onDetected()
    // const [torchOn, setTorch] = useState(false); // toggleable state for "should torch be on"
    const scannerRef = useRef(null); // reference to the scanner element in the DOM

    const [showZoomButton, setShowZoomButton] = useState(false);
    const [zoom, setZoom] = useState(1); // Initial zoom level
    const [maxZoom, setMaxZoom] = useState(1);

    const [showOverlay, setShowOverlay] = useState(false);

    let navigate = useNavigate();

    const redirectToStock = (result) => {
        console.log(result);
        if (tenant == null) return navigate('/'+result);
        const urlToNav = isNullOrUndef(substore) ? '/'+tenant+'/'+result : '/'+tenant+'/'+substore+'/'+result;
        return  navigate(urlToNav);
    }

    const handleZoomClick = (zoomNewValue) => {
        setZoom(zoomNewValue); 
      };
    
    // at start, we need to get a list of the available cameras.  We can do that with Quagga.CameraAccess.enumerateVideoDevices.
    // HOWEVER, Android will not allow enumeration to occur unless the user has granted camera permissions to the app/page.
    // AS WELL, Android will not ask for permission until you actually try to USE the camera, just enumerating the devices is not enough to trigger the permission prompt.
    // THEREFORE, if we're going to be running in Android, we need to first call Quagga.CameraAccess.request() to trigger the permission prompt.
    // AND THEN, we need to call Quagga.CameraAccess.release() to release the camera so that it can be used by the scanner.
    // AND FINALLY, we can call Quagga.CameraAccess.enumerateVideoDevices() to get the list of cameras.

    // Normally, I would place this in an application level "initialization" event, but for this demo, I'm just going to put it in a useEffect() hook in the App component.

    useEffect(() => {
        const enableCamera = async () => {
            await Quagga.CameraAccess.request(null, {});
        };
        const disableCamera = async () => {
            await Quagga.CameraAccess.release();
        };
        const enumerateCameras = async () => {
            const cameras = await Quagga.CameraAccess.enumerateVideoDevices();
            console.log('Cameras Detected: ', cameras);
            const filteredCameras = filterCameras(cameras);
            return filteredCameras;
        };
        enableCamera()
        .then(disableCamera)
        .then(enumerateCameras)
        .then((cameras) => setCameras(cameras))
        .then(() => Quagga.CameraAccess.disableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
        .catch((err) => setCameraError(err));
        return () => disableCamera();
    }, []);

    const getCameraByLabel = (cameras, selectedCameraLabel) => {
        return cameras.filter(camera => camera.label === selectedCameraLabel);
    }

    const onScannerReady = () => {
        setShowOverlay(true);
    }

    /// Load selectedCameraLabel from local storage on component mount
    useEffect(() => {
        if (cameras.length !== 0) {
            const savedCameraSelection = localStorage.getItem('selectedCameraLabel');
            if (savedCameraSelection !== null) {
                setSelectedCameraLabel(savedCameraSelection);
                const savedCamera = getCameraByLabel(cameras, savedCameraSelection)[0];
                setCameraId(savedCamera.deviceId);
            }
            setIsMounting(false); // Update mounting flag after initial render
            
        }
        
    }, [cameras]);

    // Save selectedCameraLabel to local storage whenever it changes
    useEffect(() => {
        if (!isMounting && selectedCameraLabel) {
            localStorage.setItem('selectedCameraLabel', selectedCameraLabel);
        }
    }, [selectedCameraLabel, isMounting]);

    useEffect(() => {
        if (selectedCameraLabel != null && cameras !== null){
            console.log('selectedCameraLabel', selectedCameraLabel);
            const cameraSelected = getCameraByLabel(cameras, selectedCameraLabel)[0];
            console.log('cameraSelected', cameraSelected);
            if (cameraSelected){
                const capabilities = cameraSelected.getCapabilities();
                console.log('capabliites', capabilities);
                if (capabilities.zoom){
                    
                    // setMaxZoom(capabilities.zoom.max >= ZOOM_LEVEL ? ZOOM_LEVEL : capabilities.zoom.max);
                    setMaxZoom(capabilities.zoom.max);
                    const savedZoomSelection = localStorage.getItem('selectedZoom');
                    if (savedZoomSelection !== null){
                        setZoom(Number(savedZoomSelection));
                    }
                    setShowZoomButton(true);
                }
            }
            
        }
        
    }, [selectedCameraLabel, cameras]);


    // Save selectedCameraLabel to local storage whenever it changes
    useEffect(() => {
        if (showZoomButton && zoom) {
            localStorage.setItem('selectedZoom', zoom);
        }
    }, [zoom, showZoomButton]);

    // provide a function to toggle the torch/flashlight
    // const onTorchClick = useCallback(() => {
    //     const torch = !torchOn;
    //     setTorch(torch);
    //     if (torch) {
    //         Quagga.CameraAccess.enableTorch();
    //     } else {
    //         Quagga.CameraAccess.disableTorch();
    //     }
    // }, [torchOn, setTorch]);

    return (
        <div className={ cameras.length === 0 ? 'barcodeScannerWithoutPerms' : 'barcodeScanner'}>
            
            {/* <button onClick={onTorchClick}>{torchOn ? 'Disable Torch' : 'Enable Torch'}</button> */}
            {/* <button onClick={() => setScanning(!scanning) }>{scanning ? 'Stop' : 'Start'}</button> */}
            {cameraError ? 
                <div style={{ textAlign: 'center'}}>
                    {/* <p>ERROR iniciando cámara: {JSON.stringify(cameraError)}</p> */}
                    <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                        Refrescar Página
                    </Button>
                </div> : 
                <div ref={scannerRef} style={{ border: '0px solid white' }}  >
                    {/* <video style={{ height: '100%', width: '100%', border: '3px solid red' }}/> */}
                    <canvas className="drawingBuffer" style={{
                        position: 'absolute',
                        top: '0px',
                        border: '0px solid green',
                    }} width="640" height="480" />
                    <BarcodeScanner scannerRef={scannerRef} setSelectedCameraLabel={setSelectedCameraLabel} cameraId={cameraId} zoomLevel={zoom}  onDetected={(result) => {
                        console.log('result - BarcodeScannerView', result);
                        setResult(result);
                    }} onScannerReady={() => onScannerReady()}/> 
                    {cameras.length > 0 && showOverlay && <>
                        <OverlayScanner centerHeight={160} centerWidth={340} color='rgba(0, 0, 0, 0.7)'/>
                        {showZoomButton && <div style={{ position: 'absolute', bottom: '15%', right: '3%', width: '50px', height: '100px', zIndex: 2 }}>
                            <ZoomButton zoom={maxZoom} onClick={() => handleZoomClick(maxZoom)} currentZoom={zoom}/>
                            {maxZoom > ZOOM_LEVEL && <ZoomButton zoom={ZOOM_LEVEL} onClick={() => handleZoomClick(ZOOM_LEVEL)} currentZoom={zoom}/>}
                            <ZoomButton zoom={1} onClick={() => handleZoomClick(1)} currentZoom={zoom}/>
                        </div>}
                        <div style={{ position: 'absolute', bottom: '5%', left: '3%', zIndex: 2}}> 
                            <SwitchCameraButton cameras={cameras} handleCameraSwitch={(cameraId) => setCameraId(cameraId)} selectedCameraLabel={selectedCameraLabel} />
                        </div>
                        <div style={{ position: 'absolute', top: '5%', left: '3%', zIndex: 2}}> 
                            <TextSearchButton buttonStyle={{ width: '70px' }} />
                        </div>
                    </>
                        
                    }                   
                    {result && <div style={{ position: 'absolute',
                        top: '80%', width: window.innerWidth, height: '50px' }}>
                        <BarcodeResult result={result} redirectToStock={() => redirectToStock(result)}/>
                    </div>}
                </div>
            }
            {cameras.length === 0 && 
                <p style={{ textAlign: 'center'}}>El escaner requiere permisos de acceso a tu camara</p>
            }
            
            {/* <Button onClick={() => setScanning(!scanning) } style={{ width: '25px', padding: '0', border: 'none', background: 'none' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={scanning? stopImg : playImg} alt="Open Dialog" />
            </Button> */}
        </div>
    );
};

export default BarcodeScannerView;
