export const constants = {
    LM_WEB_SERVICE: "https://lm-dapie.com:8088/stocks/",
    FORTER_WEB_SERVICE: "https://forter-services.org/POSCommWebAPI/getarticulo?pCodigoBarras=",
    FORTER_TENANT_NAME: "forter",
    FORTER_SUBSTORE_DIXIE_NAME: "dixie"
  };

// const protocol = (window.location.protocol === 'https:') ? 'https:' : 'http:';
// const protocol = "https:";

export default constants;