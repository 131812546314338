import React from 'react';

const EmailDataDisplay = ({ emailData }) => {
    return (
        <div>
            {Object.entries(emailData).map(([city, months]) => (
                <div key={city}>
                    {Object.entries(months).length > 0 ? (
                        Object.entries(months).map(([month, emails]) => (
                            <div key={month}>
                                <h6 style={{ color: 'black' }}>{city} - {month}</h6>
                                {Object.entries(emails).length > 0 ? (
                                    Object.entries(emails).map(([email, count]) => (
                                        <p key={email} style={{ color: 'black', fontSize: 10 }}>
                                            {email}: {count}
                                        </p>
                                    ))
                                ) : (
                                    <p style={{ color: 'black' }}>No hay correos para este mes.</p>
                                )}
                            </div>
                        ))
                    ) : (
                        <p style={{ color: 'black' }}>No hay datos para esta ciudad.</p>
                    )}
                </div>
            ))}
        </div>
    );
};
export default EmailDataDisplay;