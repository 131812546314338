// src/ConditionalRoute.js

import React from 'react';
import { isIOSDevice } from '../utils';
import BarcodeScannerView from './BarcodeScannerView';
import BarcodeScannerViewQuagga from './BarcodeScannerViewQuagga';

const BarcodeScannerViewConditional = (props) => {
    const ComponentToRender = isIOSDevice() ? BarcodeScannerViewQuagga : BarcodeScannerView;
    return <ComponentToRender {...props} />;
};

export default BarcodeScannerViewConditional;