import React from 'react';

const ZoomButton = ({ zoom, onClick, currentZoom }) => {
  // You can adjust styles and add logic based on the zoom value
  const buttonStyle = {
    width: 50, // Adjust width based on zoom level
    height: 50, // Adjust height based on zoom level
    borderRadius: '50%',
    backgroundColor: '#000100', // You can adjust the color
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '10px',
    border: 'none',
    opacity: currentZoom === zoom ? 1 : 0.5,
    color: 'white', // Added button text color
    fontSize: '12px', // Added button font size
    fontWeight: 'bold', // Added button font weight
    outline: 'none', // Added button outline
    transition: 'all 0.3s ease', // Added button transition
  };

  return (
      <button style={buttonStyle} onClick={onClick}>
        {zoom}x
      </button>
  );
};

export default ZoomButton;
